var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c("q-table", {
                  attrs: {
                    grid: "",
                    title: "",
                    data: _vm.grid.data,
                    columns: _vm.grid.columns,
                    "hide-header": "",
                    "hide-bottom": "",
                    "rows-per-page-options": [0],
                    "virtual-scroll": "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "item",
                      fn: function (props) {
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3",
                            },
                            [
                              _c(
                                "q-card",
                                {
                                  staticClass: "mobileTableCardLayer",
                                  on: {
                                    click: function ($event) {
                                      return _vm.linkClick(null, props.row)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "q-card-section",
                                    {
                                      staticClass: "bg-grey-2",
                                      attrs: { horizontal: "" },
                                    },
                                    [
                                      _c(
                                        "q-card-section",
                                        { staticClass: "q-pt-xs" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "text-grid-title q-mt-sm q-mb-xs",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(props.row.equipmentName)
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-card-section",
                                    { staticClass: "grid-card-etc" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "text-grid-etc-title" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$language("설비관리번호")
                                              ) +
                                              " : "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "text-grid-etc" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(props.row.equipmentNo) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
          _c("c-dialog", { attrs: { param: _vm.popupOptions } }, [_vm._v(">")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }